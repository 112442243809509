.center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0.1px solid #000000;
    padding: 10px;
}

.horizontalcenter {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    padding: 30px;
}

.navlink {
    transition: .3s;
}

.navlink:hover {
    transform: scale(1.1);
}

.round {
    border-radius: 8px !important;
}

.colored {
    background: linear-gradient(to right, rgba(30, 150, 250, 0.2), rgba(200, 30, 200, 0.2));
}
.coloredreverse {
    background: linear-gradient(to right, rgba(200, 30, 200, 0.7), rgba(30, 150, 250, 0.7));
}

.cardhover {
    transition:.3s;
}
.cardhover:hover {
    box-shadow: 5px 5px 10px rgba(30, 150, 250, 0.5);
    transform: scale(1.05);
}

.inputfocus {
    transition:.3s;
    border: none;
    background: none;
    color: white;
}

.inputfocus:focus {
    box-shadow: 5px 5px 10px rgba(30, 150, 250, 0.5);
    transform: scale(1.05);
    border: none;
    background: none;
    color: white;
    background-color: none;
}

.inputfocus::placeholder {
    color: rgb(179, 179, 179)
}